.container {
  background: #e5f3f2;
  width: 30em;
}

@media only screen and (min-width: 1000px) {
  .container {
    width: 55em;
    display: flex;
    position: relative;
    right: 14em;
  }

  .container__summary {
    margin: 1rem;
    width: 54em;
    padding-left: 29em;
    padding-right: 0.5rem;
  }
}

/* body */
.title {
  font-weight: 600;
  font-size: 1.6rem;
}

.title__model {
  font-weight: 600;
  font-size: 1rem;
  padding-bottom: 0.5rem;
  padding-top: 1rem;
}

.img__square {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 180px;
  width: 100%;
  border-radius: 5px;
  margin-bottom: 10px;
  background-color: #fff;
}

.img__preview {
  border-radius: 4px;
  transition: transform 0.2s;
  margin: auto;
  display: block;
  width: 100%;
}

.file__name {
  font-weight: 400;
  font-size: 0.75rem;
}

.noParameters {
  font-weight: 400;
}

/* Configuration */
.configuration__title {
  font-weight: 400;
  padding-bottom: 0.3rem;
}

.configuration__text {
  font-weight: 600;
  padding-bottom: 0.1rem;
}

.configuration__text_amount {
  font-weight: 600;
  padding-bottom: 0.1rem;
  padding-bottom: 0.5rem;
}

.config__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.config__container_titles {
  display: flex;
  flex-direction: column;
}

.config__conatiner_options {
  display: flex;
  flex-direction: column;
}

/* Comment */
.comment__container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.button__container {
  padding: 0 0 0.5rem 0;
}

.button {
  font-size: 14px;
  font-weight: 600;
  border: none;
  border-radius: 3px;
  padding: 6px 12px;
  cursor: pointer;
  margin-right: 10px;
  background-color: #d3ebe6;
  color: #008a80;
  text-transform: uppercase;
}

.button:hover {
  background: #008a80;
  color: white;
  border: none;
}

.comment__input {
  width: 100%;
  height: 7.2vh;
  border: none;
  border-radius: 3px;
  padding: 8px;
  font-size: 14px;
  margin-right: 10px;
  resize: none;
  font-family: inherit;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.160784);
}

.label {
  font-size: 14px;
  color: #666;
  display: flex;
  justify-content: end;
}

/* Price and Production */
.production__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.production__container_text {
  display: flex;
  flex-direction: column;
}

.production__select {
  display: flex;
}

.production__time {
  font-weight: 400;
  margin: 0;
}

.production__additional_text {
  font-weight: 400;
  font-size: 0.7rem;
  font-style: italic;
}

.price__container {
  display: flex;
  flex-direction: row;
}

.price__total {
  font-weight: 400;
}

.price__container_text {
  padding-left: 2.3rem;
}

.price__total_text {
  font-weight: 600;
  font-size: 1.7rem;
}

/* DFM error */
.error__container {
  text-align: center;
  margin: 8px 0 0px 0;
  width: 100%;
}

.error__container_box {
  width: 100%;
  font-weight: bold;
  text-transform: uppercase;
  display: flex;
  align-items: flex-end;
  align-content: center;
  flex-direction: row;
  justify-content: center;
}

.error__container_display {
  display: flex;
  align-items: stretch;
  align-content: center;
  flex-direction: row;
  color: #ff9800;
  padding-top: 1rem;
}

.error__icon {
  padding-right: 0.5rem;
}

.error__conatiner_issues {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.error__conatiner_issues_text {
  font-weight: 400;
}
