.titleText {
  font-weight: 400;
  line-height: 2em;
  font-size: 32px;
}

.subText {
  font-size: small;
}

.bodyText {
  font-size: 24px;
  line-height: 1.5em;
  font-weight: 400;
  font-size: 1.1rem;
}

.mediumTitle {
  font-size: 24px;
  font-weight: 700;
}

/* .selectorTitle {
  font-weight: bold !important;
}
@media screen and (max-width: 1000px) {
  .selectorTitle {
    font-weight: bold !important;
    font-size: 0.8rem !important;

  }
} */
