/* Order */
.container {
  background-color: #e5f3f2;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);
}

.title {
  font-weight: 700;
}

.subtext {
  font-weight: 500;
}

.details {
  font-weight: 700;
}

.table__summary {
}

.summary {
  font-weight: 600;
  font-size: 1rem;
}

.summary__info {
  font-weight: 400;
  font-size: 1rem;
}

/* OrderItemRow */
