/* Dialog */

dialog::backdrop {
  background: rgba(255, 0, 0, 0.25);
}

.dialog {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgb(46 56 77 / 30%);
  transition: all 0.3s ease-out;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000000000;
}

.dialog__content {
  background-color: #fff;
  padding: 1.5rem;
  width: 65%;
  height: min-content;
  border-radius: 0.25rem;
  background: #e9f5f2;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
}

.dialog__title {
  text-align: center;
  font-size: 1.5rem;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.dialog__message {
  text-align: center;
  margin-top: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 0.8em;
  line-height: 20px;
  padding: 5px 0px 0px 0px;
  color: #0b0c0c;
}

@media only screen and (min-width: 671px) {
  .dialog__content {
    width: 30em;
  }

  .dialog__buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
}

/* .dialog__form input {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
  border: 0.1em solid #008b7f6c;
} */

/* .dialog__backdrop {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;

} */

/* @media only screen and (min-width: 320px) {
  .dialog__content {
    width: 80%;
  }
} */

/* @media only screen and (min-width: 620px) {
  .dialog__content {
    width: fit-content;
  }
} */

/* Links */

/* .container {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  font-style: bold;
  font-weight: 700;
  justify-content: center;
}

.box__login {
  width: 100%;
  height: 300px;
  text-align: center;
  margin: 10px;
  padding-right: 10px;
  background: lightgray;
}

.box__register {
  background-color: burlywood;
  width: 100%;
  height: 100px;
  text-align: center;
  margin: 10px;
}

.box__heading {
  display: block;
  float: left;
  color: #000000;
  font-style: normal;
  font-weight: 700;
  font-size: 2rem;
  line-height: 0.5rem;
  padding-left: 10px;
}

@media (min-width: 768px) {
  .container {
    flex-direction: column;
  }

  .box {
    width: calc(50% - 20px);
  }
}

/* Form */
/* .form-label {
  display: flex;
  flex-direction: column;
}

.form-input {
  padding: 0.5rem;
  border: 1px solid gray;
  border-radius: 5px;
  display: flex;
} */
