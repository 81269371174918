body {
  /* margin: 0;
  padding: 0;
  height: 100%;
  position: relative; */
  margin: 0;
  padding: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.container {
  width: 100%;
  /* display: flex;
  flex-direction: column; */
  position: relative;

  /* flex: 1 0 auto; */
  margin-top: auto;
}

.footer {
  /* margin-top: auto; */
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;

  /* width: 100%; */
  height: 45vh; /* set the height of footer */
  background-color: var(--footer-black);
  /* display: flex;
  flex-direction: column; */
  /* justify-content: left; */
  /* align-items: left; */
  z-index: 999;
}

.footer__content {
  padding: 2em 0 0 4em;
  color: var(--primary-white);
  font-weight: 400;
  width: 90vw;
}

.footer__below {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media only screen and (min-width: 390px) {
  .footer {
    height: 35vh;
  }
}

@media only screen and (min-width: 540px) {
  .footer {
    height: 45vh;
  }
}

@media only screen and (min-width: 600px) {
  .footer {
    height: 32vh;
  }
}

@media only screen and (min-width: 1022px) {
  .footer {
    height: 50vh;
  }
}

@media only screen and (min-width: 1280px) {
  .footer {
    height: 35vh;
  }
}

/* @media only screen and (min-width: 320px) {
  .footer {
    height: 50vh;
  }
} */

/* @media only screen and (max-width: 620px) {
  .footer {
    height: 32vh;
  }
  .footer__content {
    padding: 1em 0 0 4em;
  }
  .footer__below {
    margin: 0;
  }
}

@media only screen and (min-width: 1023px) {
  .footer {
    display: flex;
    justify-content: center;
  }
  .footer__content {
    width: 60vw;
  }
} */
