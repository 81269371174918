/* Upload button */
.button__container {
  display: flex;
  position: absolute;
  top: 1em;
  right: 1em;
  align-items: center;
}

.demo__text {
  margin-top: 0.2em;
  margin-right: 1em;
  color: rgb(255, 152, 0);
  text-align: center;
  font-weight: 400;
}

.button__upload {
  background: #008a80;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 5px;
}

.button__upload:hover {
  background-color: #007474;
}

.button__upload:active {
  transition: all 0.3s;
}

.button__content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
}

.button__text {
  font-weight: 500;
  color: white;
  text-transform: uppercase;
  padding-left: 0.5rem;
}

/* Model Upload */
.model__container {
  display: flex;
  height: 60vh;
  width: 100%;
}

.model__dimensions {
  padding: 0.3em;
  position: absolute;
  bottom: 1em;
  left: 1em;
  font-weight: 400;
}
