/* Delivery Method */
.delivery__container {
  background-color: #e5f3f2;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);
}

.delivery__title {
  font-weight: bold;
  padding-top: 1rem;
  padding-left: 1rem;
  font-size: 2rem;
}

.delivery__notice {
  text-align: left;
  margin: 1rem;
  padding-bottom: 0.5rem;
  font-size: 0.8rem;
  font-weight: 400;
}

/* NewShippingRow (Delivery) */
.shipping__container {
  margin: 1rem;
}

.shipping__row {
  display: flex;
  justify-content: space-between;
  padding-left: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0rem;
  border-radius: 4px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);
}

.shipping__title {
  font-weight: bold;
  padding-top: 0.9rem;
}

@media only screen and (max-width: 595px) {
  .shipping__title {
    font-size: 0.7rem;
    padding-top: 0rem;
    padding-left: 0.2rem;
  }

  .shipping__row {
    padding-left: 0.5rem;
  }
}

.shipping__row:hover {
  /* box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3); */
  animation: pulse 0.5s ease-in-out;
  cursor: pointer;
  transform: scale(1.01);
}

.shipping__row:link,
.shipping__row:visited {
  text-transform: uppercase;
  text-decoration: none;
  padding: 15px 40px;
  display: inline-block;
  border-radius: 100px;
  transition: all 0.2s;
  position: absolute;
}

.shipping__price {
  font-weight: bold;
  font-family: inherit;
  padding: 0.9rem;
}

.shipping__img {
  height: 3em;
  width: 8em;
  object-fit: cover;
  border-radius: 5px;
}

@media only screen and (max-width: 595px) {
  .shipping__img {
    height: 2em;
    width: 7em;
  }
}

.selected__container {
  padding-left: 2rem;
  color: black;
}

.selected__container_content {
  margin: 1rem;
}

.selected__description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -2.5rem;
  font-weight: 600;
  font-size: 0.8rem;
  text-align: center;
}

.selected__description_content {
  width: 20vw;
  font-weight: 500;
}

/* PaymentMethods */
.payment__container {
  background-color: #e9f5f2;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.payment__title {
  font-weight: bold;
  padding-top: 1rem;
  padding-left: 1rem;
  font-size: 2rem;
}

/* PaymentCard */
.payment__container_row {
  display: flex;
  margin: 0.5rem;
  border-radius: 4px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);
}

.payment__container_row:hover {
  transform: scale(1.01);
}

@media only screen and (min-width: 320px) {
  .payment__row {
    height: 8rem;
  }
}

@media only screen and (min-width: 767px) {
  .payment__row {
    height: 6em;
  }
}

.icon__container {
  padding-top: 1.6rem;
  padding-left: 2.2rem;
  width: 6rem;
}

.icon__ean_pay {
  padding-left: 2.8rem;
}

.payment__row_text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media only screen and (min-width: 767px) {
  .payment__row_text {
    padding-left: 3rem;
  }
}

.payment__row_text_title {
  padding: 0.5rem;
  display: flex;
  align-items: flex-end;
  font-weight: bold;
  width: 100%;
}

.payment__row_text_sum {
  font-weight: 400;
  padding-left: 0.5rem;
}

/* @media only screen and (min-width: 767px) {
  .payment__row_text_bank {
    padding-right: 11rem;
  }

  .payment__row_text_credit {
    padding-right: 15rem;
  }

  .payment__row_text_ean {
    padding-right: 12rem;
  }

  .payment__row_text_quote {
  }

  .payment__row_text_quote_sum {
    padding-right: 6rem;
  }
} */

/* OrderDetails */
.order__container {
  /* box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3); */
  width: 100%;
}

.order__title {
  font-weight: bold;
  padding-top: 1rem;
  padding-left: 1rem;
  font-size: 2rem;
}

.table__items {
  width: 100%;
  padding-top: 1.5rem;
}

.item__name {
  /* display: inline-block; */
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 700;
  font-size: 0.7rem;
  text-transform: uppercase;
}

.name__tooltip {
  position: relative;
}

.name__tooltip .name__tooltip_text {
  visibility: hidden;
  max-width: 30vw;
  background-color: #6d6d6dd5;
  color: #fff;
  text-align: center;
  font-weight: 400;
  font-size: 0.8rem;
  border-radius: 3px;
  padding: 0.1rem 0.4rem 0.1rem 0.4rem;
  transition: all 0s ease-in-out;
  transform: translateY(2px);
  position: absolute;
  z-index: 9999;
  top: 100%;
  left: 80%;
  margin-left: -50px;
}

.name__tooltip:hover .name__tooltip_text {
  visibility: visible;
  transform: translateY(0);
}

.item__material {
  text-transform: capitalize;
  font-weight: 400;
}

.item__quantity {
  font-weight: 400;
}

.item__total {
  font-weight: 700;
  font-size: 1rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.item__perUnit {
  font-weight: 300;
  font-size: 0.75rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-style: italic;
}

.order__subtotal {
  font-weight: 400;
  font-size: 0.8rem;
}

.order__subtotal_sum {
  font-weight: 400;
  font-size: 0.7rem;
}

.line_dashed {
  /* position: absolute;
  width: 20%; */
  border-bottom: 1px dashed var(--primary-dashed-2);
}

.line_solid {
  /* position: absolute;
  width: 40em; */
  border-bottom: 1px solid var(--primary-dashed-2);
}

.line__container {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.line__container_dashed {
  padding-top: 1rem;
  padding-bottom: 0.5rem;
}

.line__container_dashed_leadTime {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.line__container_solid {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.line__container_solid_header {
  padding-top: 0.2rem;
  padding-bottom: 1rem;
}

.checkbox {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

/* OrderHistoryDetails */
.table_history__container {
  border-collapse: collapse;
  border-spacing: 0;
}
