@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,400;0,700;1,400;1,700&display=swap");

html,
body {
  margin: 0;
  height: 100%;
}

* {
  font-family: "Josefin Sans", Arial, Helvetica, sans-serif;
}

#root {
  width: 100%;
}

:root {
  --black: #212121;
  --white: #fafafa;

  --primary1: #00b28d;
  --primary2: #58e5bd;
  --primary3: #439c7e;

  --primary-login: #d3ebe6;
  --primary-note: #e9f5f2;

  --new-primary1: #008b80;
  --new-primary2: #67bab3;
  --new-primary3: #e9f5f2;
  --footer-black: #0a0b0b;
  --background-dark: #cde8e6;

  /* --primary-white: #cee8e6; */
  --primary-black: #000000;
  --primary-yellow: #e1a900;
  --primary-yellow-2: #e0a900;
  --primary-white: #ffffff;
  --primary-dashed: #858585;
  --primary-dashed-2: #707070;
  --primary-warning: #e02c4f;
  --primary-placeholder: #888888;
  --primary-button-bg: #008a80;
  --primary-link: #448583;
  --primary-font: #0b0c0c;

  --warning1: #ff9800;
  --warning2: #ffa726;
  --warning3: #fb8c00;
  --warning4: #ffa21a;
  --warning5: #f57c00;
  --warning6: #faf2cc;
  --warning7: #fcf8e3;

  --danger1: #f44336;
  --danger2: #ef5350;
  --danger3: #e53935;
  --danger4: #ff7961;
  --danger5: #d32f2f;
  --danger6: #ebcccc;
  --danger7: #f2dede;

  --success1: #4caf50;
  --success2: #66bb6a;
  --success3: #43a047;
  --success4: #5cb860;
  --success5: #388e3c;
  --success6: #d0e9c6;
  --success7: #dff0d8;
}
