.tabs {
  width: 70%;
  height: auto;
  padding-bottom: 0.4rem;
  color: var(--primary-font);
  text-decoration: none;
  @media (max-width: 769px) {
    padding: 2rem 0;
  }
}

ul.nav {
  width: 30%;
  margin: 1rem 0 2rem 0rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  color: var(--primary-font);
  font-size: 1rem;
  font-weight: 400;
  text-transform: uppercase;
  @media (max-width: 768px) {
    /* width: 100%;
    display: flex; */
    /* align-items: center; */
    /* justify-content: space-between; */
  }
}

ul.nav li {
  display: inline-block;
  list-style: none;
  text-align: center;
  cursor: pointer;
  padding-bottom: 2rem;
  color: var(--primary-font);
}

ul.nav li:hover {
  border-bottom-color: var(--primary-yellow-2);
  font-weight: 600;
}

ul.nav li:focus,
ul.nav li:active,
.tabs__components {
  color: var(--primary-font) !important;
  display: flex;
  align-items: baseline;
}

.activeTab {
  border-bottom: 0.4rem solid var(--primary-yellow-2);
  font-weight: 600;
}

@media only screen and (min-width: 768px) {
  ul.nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 2rem 0rem 1rem 12rem;
    width: 60%;
  }
}
