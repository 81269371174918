.container {
  margin: 1em;
}

.titleContainer {
  text-align: center;
  font-size: 1.5em;
  margin-bottom: 0.75em;
  margin: 1em;
}

.textContainer {
  text-align: center;
  font-size: 1.2em;
}

.icon {
  margin-bottom: 0.2em;
}
