.header {
  font-weight: 700;
  font-size: 4rem;
  color: var(--primary-black);
  margin-bottom: 30px;
  padding-left: 11rem;
}

.container {
  background-color: white;
  /* box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3); */
  display: flex;
  justify-content: center;
}

.row__border {
  border: none;
}

.line__container_solid {
  padding-top: 0rem;
  padding-bottom: 0rem;
}

.line_solid {
  border-bottom: 1px solid #c2c2c2;
}

.table__header {
  font-weight: 600;
}

.order__number {
  font-weight: 600;
  font-size: 1rem;
}

.order__date {
  font-weight: 400;
}

.order__status {
  text-transform: capitalize;
}

.order__leadTimeOption {
  font-weight: 600;
  font-size: 0.9rem;
}

.order__leadTimeDate {
  font-weight: 400;
  font-size: 0.9rem;
}

.order__total {
  font-size: 1rem;
}

.order__ink_moms {
  font-weight: 400;
  font-size: 0.8rem;
}

.order__invoice {
  /* display: flex; */
  width: 3em;
}

.order__row:hover {
  background-color: #e9f5f2;
  cursor: pointer;
}
