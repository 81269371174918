/* NewAmountInput */
.input_active:focus:valid {
  box-shadow: none;
}

#increaseNum::-webkit-outer-spin-button,
#increaseNum::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input_active {
  -moz-appearance: textfield;
}

.input_active::-webkit-outer-spin-button,
.input_active::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* CNCCartTableRow */
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltip__text {
  visibility: hidden;
  width: 120px;
  max-width: fit-content;
  background-color: #6d6d6dd5;
  color: #fff;
  text-align: center;
  font-weight: 300;
  font-size: 0.7rem;
  border-radius: 3px;
  padding: 0.1rem 0.4rem 0.1rem 0.4rem;
  transition: all 0.2s ease-in-out;
  transform: translateY(2px);
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 50%;
  margin-left: -60px;
}

.tooltip:hover .tooltip__text {
  visibility: visible;
  animation: odsoky 1s ease-in-out infinite alternate;
  -webkit-transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out,
    -webkit-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  -moz-transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out,
    -moz-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out,
    transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.file_name {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.8rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-left: 8px;
}

.item__total {
  font-weight: 700;
  font-size: 1.1rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.item__perUnit {
  font-weight: 300;
  font-size: 0.75rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-style: italic;
}

/* Basket view */
.item__line_solid {
  border-bottom: 1px solid var(--primary-dashed-2);
  position: absolute;
  width: 73.5vw;
}

.line__container_item {
  padding-top: 0rem;
  padding-bottom: 0rem;
}

.line__container {
  /* padding-top: 1rem; */
  padding-bottom: 0rem;
}
