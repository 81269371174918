.form__container {
  display: flex;
  justify-content: center;
  max-width: 435px;
}

/* Input */
.input__container {
  /* z-index: 1000000; */
  display: flex;
  flex-direction: column;
}

.input__container input {
  z-index: 1000;
  width: 100%;
  margin: 0.1rem auto;
  padding: 0.6rem;
  border: none;
  border-radius: 5px;
  transition: background-color 0.5s ease-in-out;
  text-indent: 0.3rem;
}

.label_email {
  font-weight: 700;
  color: black;
}

.label_password {
  font-weight: 700;
  color: black;
  padding-top: 1rem;
}

/* .input__validation {
  border-color: #e02c4f;
} */

.error__text {
  color: var(--primary-warning);
  font-weight: 400;
  font-size: 0.8rem;
  max-width: 10vw;
  max-height: 10vh;
}

.input__validation_text {
  color: var(--primary-warning);
  font-weight: 400;
  margin: 0;

  font-size: 0.8rem;
}

.input__validation input {
  border-color: var(--primary-warning);
}

.input__error {
  border-color: "red";
}

@media screen and (min-width: 620px) {
  .form__container .input__container {
    display: flex;
    /* justify-content: center; */
  }
  .input__container {
    width: 100%;
  }

  .form__container_register {
    justify-content: center;
  }
  /* input {
    margin: 2px;
  } */
}

.helper_text {
  display: block;
  font-size: 0.6rem;
  color: #999;
  margin-top: 0.5rem;
}

/* Placeholders */
input::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #888888;
  font-weight: 400;
  font-size: 0.7rem;
  /* padding-left: 0.5rem; */
}

input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #888888;
  font-weight: 400;
  font-size: 0.7rem;
  /* padding-left: 0.5rem; */
}

input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #888888;
  font-weight: 400;
  font-size: 0.7rem;
  /* padding-left: 0.5rem; */
}

input:-ms-input-placeholder {
  /* Internet Explorer */
  color: #888888;
  font-weight: 400;
  font-size: 0.7rem;
  /* padding-left: 0.5rem; */
}

input::placeholder {
  color: #888888;
  font-weight: 400;
  font-size: 0.7rem;
  /* padding-left: 0.5rem; */
  font-family: inherit;
}

input[type="text"]::placeholder {
  opacity: 1;
  transition: opacity 0.1s ease-in-out;
}

input[type="email"]::placeholder {
  opacity: 1;
  transition: opacity 0.1s ease-in-out;
}

input[type="password"]::placeholder {
  opacity: 1;
  transition: opacity 0.1s ease-in-out;
}

/* input[type="number"]::placeholder {
  opacity: 1;
  transition: opacity 0.1s ease-in-out;
} */

input[type="text"]:focus::placeholder {
  opacity: 0;
}

input[type="email"]:focus::placeholder {
  opacity: 0;
}

input[type="password"]:focus::placeholder {
  opacity: 0;
}

/* AutoFill and focus */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 2px #008b80;
  box-shadow: 0 0 2px #008b80;
}

/* input[type="text"]:focus {
  box-shadow: 0 0 2px #008b80;
} */

input:focus:valid {
  box-shadow: 0 0 5px #008b80;
}

input:focus:invalid {
  box-shadow: 0 0 3px #e02c4f;
}

/* .input__container.shake:invalid {
  animation-name: shake 0.5s ease-in-out;
}

.shake {
  animation-name: shake;
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
  box-shadow: 0 0 5px #e02c4f;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  20% {
    transform: translateX(-10px);
  }
  40% {
    transform: translateX(10px);
  }
  60% {
    transform: translateX(-10px);
  }
  80% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}

input.shake {
  color: aqua;
}

/* .shake {
  animation-name: shake;
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
} */

/* NewInvoiceInfo */

/* @media only screen and (min-width: 747px) {
  .input__company {
    width: 14em;
  }
} */

/* @media only screen and (min-width: 1320px) {
  .input__company {
    width: 11em;
  }
} */

@media only screen and (min-width: 555px) {
  .input__reg {
    width: 14em;
  }
}

@media only screen and (min-width: 1879px) {
  .input__two {
    width: 16em;
  }

  .input__reg {
    width: 13.5em;
  }

  .input__three {
    width: 10.3em;
  }
}

.input__validation:invalid {
  box-shadow: #e02c4f;
}
