body {
  /* margin: 0;
  background: #fff;
  display: flex;
  justify-content: center; */
  background: var(--primary-white);
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.login__container {
  /* display: flex;
  position: relative; */
  /* display: grid;
  position: center; */
  display: flex;
  position: relative;
  width: 40%;
  /* position: relative;
  width: 40%; */
}

.login_note__container {
  padding: 0px 0px 0px 0px;
}

/* Typography */
.title {
  font-weight: 700;
  text-align: left;
  margin-bottom: 2rem;
  color: var(--footer-black);
}

.title__register {
  font-weight: 700;
  text-align: left;
  margin-bottom: 0.4rem;
  color: var(--footer-black);
}

.demo__title {
  color: var(--footer-black);
}

.demo__text {
  font-style: normal;
  font-weight: 400;
  font-size: 0.8em;
  line-height: 20px;
  padding: 5px 0px 5px 0px;
  color: var(--footer-black);
}

.line {
  border-top: 1px dashed var(--primary-dashed);
  width: 17.5rem;
}

/* Containers */
.form__container {
  display: flex;
  justify-content: center;
  max-width: 435px;
}

.form__container_register {
  display: flex;
  justify-content: center;
  /* max-width: 435px; */
  padding-left: 3rem;
  padding-right: 3rem;
}

.line__container {
  padding: 30px 0px 20px 0px;
}

/* Input */
.input__container {
  display: flex;
  flex-direction: column;
}

.input__container input {
  width: 100%;
  margin: 0.2rem auto;
  padding: 0.6rem;
  border: none;
  border-radius: 5px;
  transition: background-color 0.5s ease-in-out;
}

/* input[type="text"]:focus {
  box-shadow: 0 0 2px var(--new-primary1);
} */

/* .label_email {
  font-weight: 700;
  color: var(--footer-black);
}

.label_password {
  font-weight: 700;
  color: var(--footer-black);
  padding-top: 1rem;
} */

.error__text {
  color: var(--primary-warning);
  font-weight: 400;
  font-size: 0.8rem;
  max-width: 10vw;
  max-height: 10vh;
}

.input__validation_text {
  color: var(--primary-warning);
  font-weight: 400;
  margin: 0;

  font-size: 0.8rem;
}

.input__validation input {
  border-color: var(--primary-warning);
}

.input__error {
  border-color: "red";
}

@media screen and (min-width: 620px) {
  .form__container .input__container {
    display: flex;
    justify-content: center;
  }
  .input__container {
    width: 110%;
  }

  .input__container_register {
    width: 100%;
  }

  .form__container_register {
    display: flex;
    justify-content: center;
  }

  /* .label_firmname {
    max-width: 12vw;
  } */
}

/* @media screen and (min-width: 1023px) {
  .label_firmname {
    max-width: 12vw;
  }
}

@media screen and (max-width: 1023px) {
  .label_firmname {
    width: 100%;
  }
} */

/* Placeholders */

input::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: var(--primary-placeholder);
  font-weight: 400;
  font-size: 0.7rem;
  /* padding-left: 0.5rem; */
}

input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: var(--primary-placeholder);
  font-weight: 400;
  font-size: 0.7rem;
  /* padding-left: 0.5rem; */
}

input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: var(--primary-placeholder);
  font-weight: 400;
  font-size: 0.7rem;
  /* padding-left: 0.5rem; */
}

input:-ms-input-placeholder {
  /* Internet Explorer */
  color: var(--primary-placeholder);
  font-weight: 400;
  font-size: 0.7rem;
  /* padding-left: 0.5rem; */
}

input::placeholder {
  color: var(--primary-placeholder);
  font-weight: 400;
  font-size: 0.7rem;
  /* padding-left: 0.5rem; */
  font-family: inherit;
}

/* input[type="text"]::placeholder {
  opacity: 1;
  transition: opacity 0.1s ease-in-out;
}

input[type="text"]:focus::placeholder {
  opacity: 0;
} */

/* Checkbox */
/* .check_container {
  display: flex;
  flex-direction: row;
  padding: 20px 0px 0px 0px;
} */

/* .label_check {
  display: flex;
  align-items: left;
  color: var(--primary-placeholder);
  font-weight: 400;
  font-size: 0.8rem;
  width: 100%;
  padding: 0px 0px 0px 10px;
} */

/* .checkbox {
  width: 15px;
  height: 15px;
  border-radius: 4px;
} */

/* .checkbox:before {
  position: absolute;

  opacity: 0;
} */

/* .checkbox:checked::before {
  background-color: var(--primary-white);
}

.checkbox_icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 1px solid var(--new-primary1);
  border: 1px solid var(--new-primary1);
  background-color: white; */
/* background-color: url("../assets/svg/login/checkbox.svg"); */
/* background-size: cover;
  margin-right: 8px;
} */

/* .checkbox:checked + .checkbox-icon {
  background-image: url("../assets/svg/login/check.svg");
} */

/* Buttons */
.button__container {
  padding-top: 1rem;
}

.button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  width: 100%;
  padding: 0px 20px 0px 20px;
  height: 2.5rem;
  font-weight: 700;
  font-size: 0.8rem;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  border-radius: 3px;
  font-family: inherit;
}

.button:hover {
  cursor: pointer;
}

.button:active {
  animation: pulse 0.5s ease-in-out;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.button__login {
  background: var(--primary-yellow);
  color: var(--primary-white);
  text-transform: uppercase;
}

.button__demo {
  background-color: var(--primary-login);
  color: var(--new-primary1);
  text-transform: uppercase;
}

.button__register {
  background: var(--primary-button-bg);
  color: var(--primary-white);
  text-transform: uppercase;
}

.button__login:hover {
  background: transparent;
  color: var(--primary-yellow);
  border: 2px solid var(--primary-yellow);
}

.button__demo:hover {
  background: var(--primary-button-bg);
  color: white;
  border: none;
}

.button__register:hover {
  background-color: #d3ebe6;
  color: var(--primary-button-bg);
  border: none;
}

/* Button icon */
.button__login:hover .login__icon {
  fill: var(--primary-yellow);
}

.button__demo:hover .demo__icon {
  fill: white;
}

.button__register:hover .register__icon {
  fill: var(--primary-button-bg);
}

.button__icon {
  width: 7px;
  height: 17px;
  transform: translateY(15%);
}

.login__icon {
  fill: white;
}

.demo__icon {
  fill: var(--primary-button-bg);
}

.register__icon {
  fill: #fff;
}

.dialog__buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

@media screen and (min-width: 620px) {
  .button__container .button {
    width: 110%;
  }
}

/* Links */
.submit__forgotPassword {
  padding-top: 1rem;
  font-weight: 400;
  color: var(--new-primary1);
  text-decoration: underline;
}

.submit__forgotPassword:hover a {
  color: var(--primary-link);
}

/* Dialog */

.dialog {
  background-color: rgb(46 56 77 / 30%);
  /* position: fixed; */
  transition: all 0.3s ease-out;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  z-index: 1000;
  justify-content: center;
}

.dialog__content {
  background-color: #fff;
  padding: 1.5rem;
  width: 70vw;
  max-height: 20rem;
  border-radius: 0.25rem;
  background: #e9f5f2;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
}

.dialog__title {
  text-align: center;
  font-size: 1.5rem;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.dialog__message {
  text-align: center;
  margin-top: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 0.8em;
  line-height: 20px;
  padding: 5px 0px 0px 0px;
  color: #0b0c0c;
}

/* .dialog__form input {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
  border: 0.1em solid #008b7f6c;
} */

/* .dialog__backdrop {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;

} */

/* @media only screen and (min-width: 320px) {
  .dialog__content {
    width: 80%;
  }
} */

/* @media only screen and (min-width: 620px) {
  .dialog .dialog__content {
    width: 35vh;
  }
} */

/* @media only screen and (min-width: 1022px) {
} */

/* Links */

/* .container {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  font-style: bold;
  font-weight: 700;
  justify-content: center;
}

.box__login {
  width: 100%;
  height: 300px;
  text-align: center;
  margin: 10px;
  padding-right: 10px;
  background: lightgray;
}

.box__register {
  background-color: burlywood;
  width: 100%;
  height: 100px;
  text-align: center;
  margin: 10px;
}

.box__heading {
  display: block;
  float: left;
  color: #000000;
  font-style: normal;
  font-weight: 700;
  font-size: 2rem;
  line-height: 0.5rem;
  padding-left: 10px;
}

@media (min-width: 768px) {
  .container {
    flex-direction: column;
  }

  .box {
    width: calc(50% - 20px);
  }
}

/* Form */
/* .form-label {
  display: flex;
  flex-direction: column;
}

.form-input {
  padding: 0.5rem;
  border: 1px solid gray;
  border-radius: 5px;
  display: flex;
} */
