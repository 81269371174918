.checkbox {
  display: inline-block;
  position: relative;
  padding-left: 24px;
  /* margin: 1.5em 0px 0.5em 0px; */
  cursor: pointer;
  font-size: 0.9rem;
  user-select: none;
  color: #0b0c0c;
}

.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 1rem;
  width: 1rem;
  background-color: #fff;
  border-radius: 4px;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 5px;
  top: 2px;
  width: 4px;
  height: 8px;
  border: solid #008a80;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.checkbox input:checked ~ .checkmark {
  background-color: #fff;
  border-color: #6276b3;
}

.checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* .check_container {
  display: flex;
  flex-direction: row;
  padding: 20px 0px 0px 0px;
} */
