.dropdown {
  position: relative;
  display: inline-block;
}

.dropdownButton {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: white;
  /* border: 1px solid #d2d2d2; */
  border: none;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  color: #000;
  cursor: pointer;
  font-family: inherit;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  outline: none;
  padding: 8px 12px;
  transition: border-color 0.2s ease-in-out;
  width: 100%;
  padding-right: 0.9rem;
  text-transform: capitalize;
}

.dropdownContent {
  display: block;
  position: absolute;
  right: 30%;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  font-weight: 400;
  padding: 1rem;
}

.dropdownItem {
  display: block;
  padding: 8px 16px;
  cursor: pointer;
}

.dropdownItem:hover {
  background-color: #f1f1f1;
}

.dropdownArrow {
  display: inline-block;
  width: 0;
  height: 0;
  padding-left: 20px;
  padding-bottom: 17px;
  vertical-align: middle;
  /* border-top: 6px solid #333;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent; */
}
