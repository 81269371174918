.select {
  height: 4vh;
  width: 11vw;
  background: #ffffff;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.160784);
  border-radius: 6px;
  border: none;
  font-family: inherit;
  padding-left: 1rem;
  font-size: 0.9rem;
}

select option {
  color: var(--primary-font);
  font-size: 0.9rem;
  width: 100%;
  height: 100%;
  padding: 0 10px !important;
  box-sizing: border-box;
}

.select__finish {
  height: 4vh;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.160784);
  border-radius: 6px;
  border: none;
  font-family: inherit;
  padding-left: 1rem;
  font-size: 0.9rem;
}

/* multiple select */
.multiple__select {
  display: inline-block;
  position: relative;
}

.multiple__select summary {
  padding: 5px 10px;
  background-color: #ddd;
  border-radius: 5px;
}

.multipl__select_dropdown {
  position: absolute;
  margin-top: 2px;
  min-width: 100%;
  background-color: #ddd;
  border-radius: 5px;
}

.multiple__select label {
  margin: 0;
  display: block;
}

.multiple__select label > input + .content {
  padding: 5px 10px;
  display: block;
  cursor: pointer;
}

.multiple__select label + label {
  border-top: 1px solid #bbb;
}

.multiple__select label > input:checked + .content {
  background-color: #bbb;
}
