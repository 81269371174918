.container {
  flex-grow: 1;
  display: flex;
  font-size: 32px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.container__loader {
  display: flex;
  align-items: center;
  color: #474848;
  font-weight: 400;
}

.loader__text {
  padding-top: 1rem;
  color: #474848;
  font-size: 1.3rem;
}

.upload__container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--black);
}

.upload__container_text {
  padding-top: 1em;
  font-weight: 500;
  font-size: 1rem;
}
