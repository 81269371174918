.container__account {
  display: flex;
  justify-content: center;
  width: 90%;
  background: #d3ebe6;
  height: 50%;
}

.container__addresses {
  width: 90%;
  background: #e9f5f2;
  /* padding-left: 3rem; */
}

@media only screen and (min-width: 768px) {
  .container {
    display: flex;
    justify-content: center;
    padding: 1rem;
  }

  .container__account {
    width: 30em;
    position: relative;
    left: 5em;
    display: flex;
    justify-content: flex-start;
  }

  .profile__info {
    text-align: left;
    padding-left: 6rem;
    padding-bottom: 1rem;
  }

  .container__addresses {
    width: 50em;
    padding-left: 5rem;
  }
}

@media only screen and (max-width: 766px) {
  .container {
    padding-left: 2rem;
  }
}

/* Profile box */
.title {
  font-weight: 700;
  text-align: left;
  margin-bottom: 1rem;
  color: var(--footer-black);
  padding-top: 1rem;
  padding-left: 1rem;
}

.title__info {
  font-weight: 600;
  font-size: 1.1rem;
  padding-top: 0.5rem;
}

/* Address box */
.address__headers {
  font-weight: 600;
  font-size: 2rem;
  padding-left: 1rem;
}

.checkbox {
  padding: 2rem 0 1rem 2rem;
}

.button__container {
  display: flex;
  flex-direction: row;

  padding-bottom: 2rem;
  padding-top: 1.3rem;
  width: 100%;
}

@media only screen and (min-width: 501px) {
  .button__container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-bottom: 2rem;
    padding-top: 1.3rem;
    width: 100%;
  }
}

@media only screen and (max-width: 500px) {
  .button__container {
    display: flex;
    flex-direction: column;
    padding: 1.3rem 1rem 2rem 1rem;
    width: 100%;
  }
}

@media only screen and (min-width: 772px) {
  .button__container {
    display: flex;
    flex-direction: column;
    padding: 1.3rem 1rem 2rem 1rem;
    width: 100%;
  }
}

@media only screen and (min-width: 810px) {
  .button__container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 1.3rem 1rem 2rem 1rem;
    width: 100%;
  }
}
