/* .container {
} */
.button {
  padding: 0.5em;
  justify-content: space-between;
  /* color: var(--danger1); */
  text-transform: none;
  border: 1px solid grey;
  /* border-radius: 0 !important;*/
  border-top: 0 !important;
}
