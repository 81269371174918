nav {
  display: flex;
  align-items: left;
  /* justify-content: space-between; */
  padding: 1rem;
  /* z-index: 100; */
}

.navbar__container {
  /* display: flex;
  flex-direction: row;
  justify-content: space-between; */
  /* position: relative; */
  /* width: 1900px; */
}

.navbar__left {
  flex-grow: 1;
}

.navbar__right {
  display: flex;
  align-items: center;
  /* margin-top: -2rem; */
}

@media only screen and (max-width: 768px) {
  .navbar__container {
  }
}

@media only screen and (max-width: 420px) {
  nav {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media only screen and (max-width: 1023px) {
  .navbar__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

/* Select */
.language_button {
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  background: transparent;
  color: #858585;
  font-weight: 400;
}

.language_button:focus,
.language_button:active,
.activeLanguageButton {
  color: #008a80;
  font-weight: 700;
  border-bottom: 2px solid #008a80;
}

.line {
  width: 14px;
  height: 0px;
  border: 0.09em solid #888888;
  transform: rotate(90deg);
}
