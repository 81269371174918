.input_active:focus:valid {
  box-shadow: none;
}

#postnumber::-webkit-outer-spin-button,
#postnumber::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input_active {
  -moz-appearance: textfield;
}

.input_active::-webkit-outer-spin-button,
.input_active::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
