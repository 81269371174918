.pulse {
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  animation: pulse-green 2s infinite !important;
}

@keyframes pulse-green {
  0% {
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0.7);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(51, 217, 178, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0);
  }
}
