.container__configurator {
  width: 90%;
  background: #d3ebe6;
}

@media only screen and (min-width: 599px) {
  .container__configurator {
    width: 90%;
  }
}

@media only screen and (min-width: 1000px) {
  .container__configurator {
    width: 50vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 1;
    left: 14em;
  }
}

@media only screen and (min-width: 1200px) {
  .container__configurator {
    width: 50vw;
  }
}

.title {
  font-weight: 700;
  text-align: left;
  margin-bottom: 0.5rem;
  color: #000;
  font-size: 4.3em;
  padding-top: 1rem;
  padding-left: 1rem;
}
