.container {
  margin-bottom: 0.5em;
  border: 2px solid transparent;
  border-radius: 4px;
  transition: 300ms;
}
.container:last-child {
  margin-bottom: 0;
}
.containerHover {
  border: 2px solid #00b28d;
  border-radius: 4px;
}
.root {
  min-width: 1em;
  transition: 300ms;
  padding: 1em;
  background-color: #f5f5f5;
}
.rootHover {
  background-color: #ffffff;
}
.cardContent:first-child {
  padding-bottom: 0 !important;
}
.cardContent:last-child {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.cardHeader {
  display: flex;
  justify-content: space-around;
  margin: 0.5em;
}
.title {
  font-size: 14;
  font-weight: bold;
}
.pos {
  margin-bottom: 12;
}
.headerText {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.65);
  font-weight: 400;
  margin-right: 0.5ch;
  user-select: none;
}
.headerValue {
  font-size: 15px;
  color: rgba(0, 0, 0, 0.65);
  font-weight: 700;
  text-align: right;
}
.fatText {
  font-weight: bold;
}
.formControl {
  min-width: 90% !important;
  margin-top: 1em;
  margin-bottom: -20px;
}
.formControlSecondaryContainer {
  margin-top: 0.2em;
  margin-bottom: 1em !important;
}
.formControlSecondary {
  min-width: 90% !important;
}
.formControlSecondary:hover {
  border-color: #00b28d !important;
  color: #00b28d;
}
.formControlInput {
  /* color: #00b28d; */
  font-family: inherit;
}
.selectEmpty {
  margin-top: 1em;
}
.expand {
  transform: rotate(0deg);
  margin-right: auto;
  transition: 500ms;
}
.expandOpen {
  transform: rotate(180deg);
}
.expandContainer {
  display: flex;
  padding-top: 1.5rem;
}
.smallText {
  font-size: 0.75em !important;
  hyphens: auto;
  word-wrap: auto;
  margin-bottom: 1em;
}
.selectEmpty {
  margin-top: 1em;
}
