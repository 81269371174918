/* Buttons */
.button__container {
  padding-top: 1rem;
}

.button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  width: 100%;
  padding: 0px 20px 0px 20px;
  min-height: 2.5rem;
  font-weight: 700;
  font-size: 0.8rem;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  border-radius: 3px;
  font-family: inherit;
}

.button:hover {
  cursor: pointer;
}

.button:active {
  animation: pulse 0.5s ease-in-out;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.button__login {
  background: var(--primary-yellow);
  color: var(--primary-white);
  text-transform: uppercase;
  border: solid transparent 2px;
}

.button__demo {
  background-color: #d3ebe6;
  color: #008a80;
  text-transform: uppercase;
}

.button__register {
  background: #008a80;
  color: var(--primary-white);
  text-transform: uppercase;
}

.button__basket {
  background: #008a80;
  color: var(--primary-white);
  text-transform: uppercase;
  width: 150vw;
}

.button__logout {
  background: var(--primary-warning);
  color: var(--primary-white);
  text-transform: uppercase;
}

.button__cancel {
  background: var(--primary-warning);
  color: var(--primary-white);
  text-transform: uppercase;
}

.button__login:hover {
  background: transparent;
  color: var(--primary-yellow);
  border: 2px solid var(--primary-yellow);
}

.button__login:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.button__demo:hover {
  background: #008a80;
  color: white;
  border: none;
}

.button__register:hover {
  background-color: #d3ebe6;
  color: #008a80;
  border: none;
}

.button__register:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.button__basket:hover {
  background-color: #d3ebe6;
  color: #008a80;
  border: none;
}

.button__logout:hover {
  background-color: transparent;
  color: var(--primary-warning);
  border: 2px solid var(--primary-warning);
}

.button__cancel:hover {
  background-color: transparent;
  color: var(--primary-warning);
  border: 2px solid var(--primary-warning);
}

/* Button icon */
.button__login:hover .login__icon {
  fill: var(--primary-yellow);
}

.button__demo:hover .demo__icon {
  fill: white;
}

.button__register:hover .register__icon {
  fill: #008a80;
}

.button__basket:hover .basket__icon {
  fill: #008a80;
}

.button__logout:hover .logout__icon {
  fill: var(--primary-warning);
}

.button__cancel:hover .cancel__icon {
  fill: var(--primary-warning);
}

.button__icon {
  width: 7px;
  height: 17px;
  transform: translateY(15%);
}

.login__icon {
  fill: white;
}

.demo__icon {
  fill: #008a80;
}

.register__icon {
  fill: #fff;
}

.basket__icon {
  fill: #fff;
}

.logout__icon {
  fill: #fff;
}

.cancel__icon {
  fill: #fff;
}

@media only screen and (min-width: 500px) {
  .button__logout {
    width: 9em;
  }

  .button__update {
    width: 20em;
  }
}

@media only screen and (min-width: 620px) {
  .button {
    width: 100%;
  }

  .button .button__demo .button__register {
    width: 100%;
  }

  .button__logout {
    width: 10em;
  }

  .button__update {
    width: 25em;
  }
}

@media only screen and (min-width: 621px) {
  .button__container .button__basket {
    width: 13rem;
  }
}

@media only screen and (max-width: 620px) {
  .button__basket {
    width: 13rem;
  }
}

.history__row_info {
  width: 130%;
}
