/* DFM controls */
.container {
  position: absolute;
  top: 1em;
  left: 1em;
  border-radius: 4px;
}

.container__text {
  display: flex;
  margin: 0 1em 0 1em;
  align-items: center;
}

.loader {
  display: flex;
  align-items: center;
  font-weight: 400;
}

.loader__text {
  font-weight: 400;
  padding-left: 1rem;
}
