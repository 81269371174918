.container {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 4px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.container:hover {
  border-color: #2196f3;
}

.focused {
  border-color: #2196f3;
}

.accept {
  border-color: #00e676;
}

.reject {
  border-color: #ff1744;
}
