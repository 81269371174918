body {
  color: black;
}

.table__items {
  width: 75vw;
  /* width: 1624px; */
}

.table__subtotal {
  display: flex;
  flex-direction: row-reverse;
  padding-top: 1rem;
  width: 74vw;
}

.button__proceed {
  display: flex;
  flex-direction: row-reverse;
  /* padding: auto; */
  /* margin-left: 43rem; */
}

/* Subtotal */
.title {
  font-weight: 700;
  font-size: 4rem;
  color: var(--primary-black);
  margin-bottom: -20px;
}

.subtotal__text {
  font-weight: 400;
  font-size: 0.8rem;
  text-transform: uppercase;
}

.subtotal__text_price {
  font-weight: 400;
  font-size: 0.8rem;
}

.subtotal__text_sum {
  font-weight: 300;
  font-size: 0.8rem;
}

.subtotal {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 1rem;
}

.subtotal__price {
  font-weight: 600;
  font-size: 1rem;
}

.line_dashed {
  position: absolute;
  width: 15rem;
  border-bottom: 1px dashed var(--primary-dashed-2);
}

.line_solid {
  position: absolute;
  width: 15rem;
  border-bottom: 1px solid var(--primary-dashed-2);
}

.line__container {
  padding-top: 1rem;
  padding-bottom: 0.5rem;
}

.line__container_dashed {
  padding-top: 1rem;
  padding-bottom: 0.5rem;
}

.line__container_solid {
  padding-top: 1rem;
  padding-bottom: 0.3rem;
}

@media only screen and (min-width: 840px) {
  .line_dashed {
    width: 16rem;
  }

  .line_solid {
    width: 16rem;
  }
}

@media only screen and (min-width: 1040px) {
  .line_dashed {
    width: 17rem;
  }

  .line_solid {
    width: 17rem;
  }
}

@media only screen and (min-width: 1250px) {
  .line_dashed {
    width: 18rem;
  }

  .line_solid {
    width: 18rem;
  }
}

@media only screen and (min-width: 1370px) {
  .line_dashed {
    width: 19rem;
  }

  .line_solid {
    width: 19rem;
  }
}

@media only screen and (min-width: 1570px) {
  .line_dashed {
    width: 20rem;
  }

  .line_solid {
    width: 20rem;
  }
}

@media only screen and (min-width: 1720px) {
  .line_dashed {
    width: 21rem;
  }

  .line_solid {
    width: 21rem;
  }
}

@media only screen and (min-width: 1880px) {
  .line_dashed {
    width: 22.5rem;
  }

  .line_solid {
    width: 22.5rem;
  }
}

/* Empty basket */
.basket__empty {
  padding-top: 2rem;
  font-weight: 400;
  font-size: 2rem;
}

.basket__empty_click {
  padding-top: 1rem;
  font-weight: 400;
  font-size: 2rem;
  cursor: pointer;
  color: var(--new-primary1);
}

.basket__empty_click:hover {
  box-sizing: border-box;
  border-bottom: 2px solid var(--new-primary1);
  width: 7.6em;
  margin: 0px;
  text-align: center;
}
