.card {
  width: 550px;
  height: 500px;
  padding: 2rem;
  margin: 1rem;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
}

.card__login {
  background-color: var(--primary-login);
  z-index: 1;
}

.card__register {
  background-color: var(--primary-login);
  z-index: 1;
}

.card__login_note_register {
  height: 72vh;
}

.card__register {
  height: 70vh;
}

@media only screen and (min-width: 320px) {
  .card__register {
    height: 110vh;
  }
}

@media only screen and (min-width: 360px) {
  .card__register {
    height: 100vh;
  }
}

@media only screen and (min-width: 390px) {
  .card__register {
    height: 90vh;
  }
}

@media only screen and (min-width: 412px) {
  .card__register {
    height: 75vh;
  }
}

@media only screen and (min-width: 540px) {
  .card__register {
    height: 90vh;
  }
}

/* @media only screen and (min-width: 620px) {
  .card__register {
    height: 160vh;
  }
} */

@media only screen and (max-width: 620px) {
  .card {
    width: 90vw;
    max-height: 300vh;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .card__login_note {
    width: 90vw;
  }

  /* .card__register {
    height: 150vh;
  } */
}

@media only screen and (max-width: 670px) {
  .card__login_note {
    height: 550px;
  }
}

/* Card Login NOTE */
.card__login_note {
  background-color: var(--primary-note);
}

/* @media only screen and (max-width: 1022px) {
  .card__register {
    height: 120vh;
  }
} */

/* Layout of the page */
@media only screen and (min-width: 1023px) {
  .layout__container {
    width: 300px;
    height: 300px;
    position: relative;
    margin: 20px;
  }

  .layout .layout__container {
    top: 15%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: (-50%, -50%); /* WebKit */
    -moz-transform: (-50%, -50%); /* Mozilla */
    -o-transform: (-50%, -50%); /* Opera */
    -ms-transform: (-50%, -50%); /* Internet Explorer */
    transform: (-50%, -50%); /* CSS3 */
  }

  .card__login {
    max-width: 28vw;
    height: 55vh;
    position: absolute;
    top: 50%;
    right: 30%;
  }

  .card__register {
    max-width: 50vw;
    height: 710px;
    position: absolute;
    top: 50%;
    right: 30%;
  }

  .card__login_note {
    max-width: 190%;
    position: absolute;
    top: 50%;
    padding-left: 250px;
    padding-right: 3rem;
  }

  .card__login_note_s {
    z-index: 0;
    left: -10%;
    height: 65vh;
  }

  .card__login_note_register {
    z-index: 0;
    left: -10%;
    height: 740px;
  }
}

/* @media only screen and(min-width: 1920px ) {
  .card__register {
    
  }
} */
