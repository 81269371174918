.layout__register {
  height: 200vh;
}

.layout__login {
  height: 90vh;
}

@media only screen and (min-width: 320px) {
  .layout__register {
    height: 110vh;
  }

  .layout__login {
    height: 170vh;
  }
}

@media only screen and (min-width: 360px) {
  .layout__register {
    height: 210vh;
  }
}

@media only screen and (min-width: 390px) {
  .layout__register {
    height: 180vh;
  }
  .layout__login {
    height: 130vh;
  }
}

@media only screen and (min-width: 414px) {
  .layout__register {
    height: 150vh;
  }
}

@media only screen and (min-width: 540px) {
  .layout__register {
    height: 190vh;
  }
}

@media only screen and (min-width: 1022px) {
  .layout__register {
    height: 130vh;
  }
}

/* @media only screen and (max-width: 671px) {
  .layout__login {
    height: 130vh;
  }

  .layout__register {
    height: 210vh;
  }
}

@media only screen and (min-width: 1022px) {
  .layout__register {
    height: 120vh;
  }
} */
