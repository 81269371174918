.noButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  /* position: relative;
  z-index: -1; */
}

.icon_button:hover {
  /* width: 2vw; */
  /* height: 4vh; */
  /* border-radius: 50%; */
  /* display: flex;
  align-items: center;
  justify-content: center; */
  /* box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.160784); */
  /* padding-top: 5px; */
  /* position: relative; */
  /* z-index: 1; */
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); TODO Kinda works, but right now it does not look good in basket */
}

.icon {
  position: relative;
  bottom: 50%;
  padding-left: 8px;
  /* z-index: 2; */
}

.icon_pdf {
  background: var(--new-primary1);
}

.tooltip {
  position: relative;
  /* z-index: -1; */
  /* display: inline-block; */
}

.tooltip .tooltip__text {
  visibility: hidden;
  max-width: 30vw;
  background-color: #6d6d6dd5;
  color: #fff;
  text-align: center;
  font-weight: 400;
  font-size: 0.8rem;
  border-radius: 3px;
  padding: 0.1rem 0.4rem 0.1rem 0.4rem;
  transition: all 0s ease-in-out;
  transform: translateY(2px);
  position: absolute;
  z-index: 9999;
  top: 100%;
  left: 80%;
  margin-left: -50px;
}

.tooltipClass {
  width: 7vw;
}

.tooltip:hover .tooltip__text {
  visibility: visible;
  /* z-index: 9; */
  animation: odsoky 1s ease-in-out infinite alternate;
  -webkit-transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out,
    -webkit-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  -moz-transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out,
    -moz-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out,
    transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

/* .order__invoice_style {
  z-index: 999;
}

.order__invoice_tooltip {
  z-index: 9999;
} */
