@media only screen and (max-width: 766px) {
  .container {
    padding-left: 2rem;
  }
}

.container__configurator {
  display: flex;
  justify-content: center;
  width: 50vw;
}

@media only screen and (min-width: 1000px) {
  .container {
    display: flex;
    justify-content: center;
    padding: 1rem;
  }

  .container__configurator {
    /* width: 60em; */
    position: relative;
    left: 5em;
    display: flex;
    justify-content: flex-start;
    width: 50vw;
  }
}

@media only screen and (max-width: 766px) {
  .container {
    padding-left: 2rem;
  }
}
